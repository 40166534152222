import React, { useState, useEffect } from 'react';

const TEXTS = ['website', 'portfolio', 'shop', 'blog', 'gallery'];

const TextCarousel = () => {
    const [text, setText] = useState(TEXTS[0]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) {
            const interval = setInterval(() => {
                const nextIndex = TEXTS.indexOf(text) + 1;

                setText(TEXTS[nextIndex % TEXTS.length]);
            }, 600);

            return () => clearInterval(interval);
        }
    }, [text, loaded]);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 2000);
    }, [text]);

    return <span>{text}</span>;
};

export default TextCarousel;
