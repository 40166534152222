import React from 'react';

interface Props {
    text: string;
}

const StrikeThrough: React.FC<Props> = ({ text }) => {
    const words = text.split(' ');

    return (
        <span>
            {words.map((word, index) => (
                <span key={index} className="strike">
                    {word}
                    {index !== words.length - 1 && ' '}
                </span>
            ))}
        </span>
    );
};

export default StrikeThrough;
