import { Link } from 'gatsby';
import React, { useState, useRef } from 'react';
import SubTitle from '../SubTitle';
import './Creation-flow.scss';

const steps = [
    {
        title: 'You show me yours',
        body: (
            <>
                <p>
                    We talk about <strong>your world</strong>,{' '}
                    <strong>your ideas</strong>, <strong>your needs</strong>
                </p>
                <p>
                    In short: everything you need and want to tell me so I
                    understand you and your work.
                </p>
                <p>
                    The more you show me, the better I will be prepared for you.
                </p>
            </>
        ),
    },
    {
        title: 'I show you mine',
        body: (
            <>
                <p>
                    After having some drinks and some time, I come back to you
                    and show you the quickest and most viable way to translate
                    your work into web.
                </p>
                <p>
                    There will probably be a lot of things you might want to
                    look different at this point.
                </p>
                <p>This is the perfect time to say it!</p>
            </>
        ),
    },

    {
        title: 'We come together',
        body: (
            <>
                <p>
                    Ideas are great. And a good understanding is always better.
                    That's why, after getting to know each other's ideas, we
                    work towards a prototype that pleases us both.
                </p>
                <p>
                    <strong>No compromises</strong>, unless you want to. It is
                    like your usual creative cooperation, just this time it is{' '}
                    <strong>all about you</strong>.
                </p>
                <p>
                    At this point the most important thing for me will be to be
                    sure you are happy with the concept and looking forward to
                    have this online.
                </p>
            </>
        ),
    },
    {
        title: 'We go online',
        body: (
            <>
                <p>
                    After some development time and short meetings and reviews,
                    we are done.
                </p>
                <p>
                    You get your result and can show the world your amazing work
                    on your amazing site.
                </p>
                <p>
                    {' '}
                    I won't promise you love, but damn, it sounds a hell like
                    it!
                </p>
            </>
        ),
    },
];

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const CreationFlow = () => {
    const [currentStep, seCurrentStep] = useState(1);
    const flowRef = useRef(null);

    const executeScroll = () => scrollToRef(flowRef);

    const handleSteps = (step) => {
        if (currentStep + step <= steps.length && currentStep + step > 0) {
            seCurrentStep(currentStep + step);
        }
        executeScroll();
    };

    return (
        <section className="front block-image-bg" id="service">
            <div className="padded wrapper gap-top-lg no-scrollbar gap-bottom-lg">
                <div className="magazine">
                    <SubTitle pre="this is how we do it">
                        <h1 className="claim"> Let's do it together</h1>
                    </SubTitle>
                    <div className="description">
                        <p>
                            It is quite simple. Really. And the best part: all
                            we need to do it talk.
                        </p>
                        <p>
                            It's almost like dating, or like a cooperation, but
                            more fun. A lot more fun.
                            <a href="#Flow">
                                <strong>Curious how it works?</strong>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <section
                className="magazine description flow no-scrollbar"
                id="Flow"
                onMouseEnter={executeScroll}
                ref={flowRef}
            >
                <button
                    className="counter --big"
                    type="button"
                    onClick={() => handleSteps(-1)}
                >
                    {currentStep}
                </button>
                <ol className="wrapper full-height">
                    {steps.map(({ title, body }, index) => (
                        <li
                            key={index}
                            className={`${
                                index === currentStep - 1 ? 'current' : ''
                            } ${index < currentStep - 1 ? 'previous' : ''}`}
                        >
                            <h2>
                                <span className="counter --small">
                                    {currentStep}
                                </span>
                                <span className="flow-title">{title}</span>
                            </h2>
                            <div>
                                {body}
                                <div className="wrapper gap-bottom-md">
                                    {index === steps.length - 1 ? (
                                        <Link
                                            className="cta-button"
                                            to="/contact"
                                        >
                                            more info
                                        </Link>
                                    ) : (
                                        <button
                                            className="cta-button"
                                            type="button"
                                            onClick={() => handleSteps(1)}
                                        >
                                            next
                                        </button>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ol>
            </section>
        </section>
    );
};

export default CreationFlow;
