import * as React from 'react';
import proptypes from 'proptypes';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubTitle from '../components/SubTitle';
import Title from '../components/Title';
import HTMLParser from '../components/HTMLParser';
import SEO from '../components/SEO';
import CreationFlow from '../components/CreationFlow';
import TextCarousel from '../components/TextCarousel';
import StrikeThrough from '../components/StrikeTrough';

const IndexPage = ({ data }) => {
    const sections = data.allMarkdownRemark.nodes;

    return (
        <Layout>
            <SEO
                title="Home"
                description={`Get from "I have an idea" to "I have an amazing website" now!`}
            />
            <Title pre="at castrum doloris design" post="come to life">
                <h1 id="initialClaim1" className="claim">
                    we make your
                    <br />
                    <StrikeThrough text="average template" />
                    <br />
                    <span className="invisible">dream</span>
                    <TextCarousel />
                </h1>
            </Title>
            {sections.map(({ frontmatter, html }) => (
                <section
                    className="front"
                    id={frontmatter.id}
                    key={frontmatter.id}
                >
                    <div className="padded wrapper full-height">
                        <div className="magazine">
                            <SubTitle pre={frontmatter.title}>
                                <h1 className="claim"> {frontmatter.claim}</h1>
                            </SubTitle>
                            <HTMLParser markdown={html} />
                        </div>
                        {frontmatter.url !== null && (
                            <Link className="cta-button" to={frontmatter.url}>
                                {frontmatter.ctaText || 'more info'}
                            </Link>
                        )}
                    </div>
                </section>
            ))}
            <CreationFlow />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: proptypes.object,
};

export const indexSections = graphql`
    query IndexPage {
        allMarkdownRemark(
            sort: { fields: frontmatter___nr, order: ASC }
            filter: { frontmatter: { parent: { eq: "index" } } }
        ) {
            nodes {
                html
                frontmatter {
                    parent
                    claim
                    id
                    nr
                    title
                    url
                    ctaText
                }
            }
        }
    }
`;
export default IndexPage;
